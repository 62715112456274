export type RegionCodes = string;

export const regionCodes: readonly RegionCodes[] = Object.freeze([
  "IT-21",
  "IT-23",
  "IT-25",
  "IT-32-BZ",
  "IT-32-TN",
  "IT-34",
  "IT-36",
  "IT-57",
]);

export const regionsRegex = /^(IT-)/;
